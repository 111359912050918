import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpeg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin,} from "@fortawesome/free-brands-svg-icons"

class Main extends React.Component {
  render() {
    let close = (
      <div
        role="button"
        tabIndex="0"
        aria-label="close"
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <h6>My Past</h6>
          <p>
          From age 8 I started studying DOS 3.3, one of my best friends dad was a major developer for IBM in the 80’s , I got to understand and work with computers from an early age.
          Then I continued to learn MS office and AutoCAD in my teens, continued working on assembly of computers, and acquired a networking degree in Peru.
          I started a new IT support company in 2006 in Boulder, CO, this was my first venture for making IT my career goal.
          </p>
          <h6>My Present</h6>
          <p>
          At age 27 I started a new job as a Bilingual Consultant at a company named ThinPrint which I still work for.
          I am a passionate, challenge driven person, my favorite sports are snowboarding, kickboxing, swimming, hiking, rock climbing, and tennis.
          I am driven by goals and challenges, the bigger the challenge, the greater the satisfaction.
          I love to learn new things especially related to technology.
          My passion for coding became my carrer. I love the vast universe of technologies that coding have allowed me to learn.
          If you would like to see more about this please click on my “Developer” tab.
          </p>
          <h6>My Future</h6>
          <p>
          I am now looking for the next milestone, to master more traditional programing languages such as Java, C++, C#/.Net and continue to grow my knowdlege on JavaScript, Python, Groovy, GOlang, etc.
          New challenges are welcome! I am really looking forward to learn about new technologies and make a difference in this world with my experience and creations.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Solutions Architect</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
          I have been in the technical consulting world for over fifteen years wearing many hats, such as, software architect, software trainer, support specialist, solutions engineer and many more.
          </p>

          <p>
          In my day to day I manage and execute end-to-end the proof of concept projects for North America and South America in English and Spanish.
          I specialized on Pre-sales and integrations, focused on the precise tailoring of software features to quickly achieve customer goals and expectations.
          </p>

          <p>
          Started my technical career as Latin America consultant to pre-sales team leader. Address multiple tasks in the company such as internal IT, network design, software architecting,
          customer trainings, live implementations, managed proof of concept projects of many scales, business analysis for many market verticals and staff training for resellers and end customers.
          </p>
          <p>
          I am knowledgeable in Windows administration, Linux administration, cloud systems, VMWare, Citrix, web services, networking, active directory, exchange,
          AD architecture, AD LDAP, ADLDS, PowerShell, Bash, mobile device applications and automation.
          Additionally experienced in ERP systems, SAP, Linux back end batch processes, Hyper-V, RDHS, Citrix virtualization, EMM solutions, Cisco networking, and Cybersecurity.
          Now I am hungry for more experience.
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Developer</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
          Development for me started as a curiosity after working in-depth with debugging code.
          I became curious how the data flowed- what is the data doing? How can we further control the code?
          This brought me to start scripting more, and automating with tools like PowerShell and VBS.
          I am proficient on a variety of technologies such as Lambda, Java, Java Script, Python, Apache Tomcat, Jetty, AWS terraform.
          On the back end side I am very comfortable with AWS cloud, Azure Cloud, Docker, Percona.
          Avid with multiple editors such as IntelliJ, Visual Studio and Visual Studio Code.
          </p>
          <h6>I have worked so far with:</h6>
          <ol>
            <li>HTML5</li>
            <li>WebPlatforms</li>
              <ul>
                <li>Firebase</li>
                <li>Heroku</li>
                <li>Netify</li>
                <li>GitHub</li>
               </ul>
            <li>Java</li>
              <ul>
                <li>Dropwizard</li>
                <li>Spring Boot</li>
                <li>JUnit</li>
                <li>Mockito</li>
                <li>Redis</li>
                <li>Lombok</li>
               </ul>
            <li>Node JS</li>
            <li>JavaScript</li>
              <ul>
                <li>Jquery</li>
                <li>React</li>
                <li>Gatsby(this page)</li>
              </ul>
            <li>CSS</li>
              <ul>
                <li>SCSS</li>
                <li>Materialize</li>
                <li>MateriaUI</li>
                <li>Bootstrap</li>
              </ul>
            <li>Python</li>
              <ul>
                <li>Django</li>
                <li>Flask</li>
              </ul>
            <li>Databases</li>
              <ul>
                <li>MongoDB</li>
                <li>PosgreSQL</li>
                <li>SQL Express</li>
                <li>SQL Lite</li>
                <li>My SQL</li>
              </ul>
          </ol>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contact"  method="post" data-netlify="true" >
          <input type="hidden" name="contact" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <div data-netlify-recaptcha="true"></div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://github.com/hnorthcote/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
              </a>
            </li>
           <li>
                <a href="https://www.linkedin.com/in/henry-northcote/" target="_blank" rel="noreferrer">
                   <FontAwesomeIcon icon={faLinkedin} />
               </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
