import PropTypes from 'prop-types'
import React from 'react'
import resume from '../downloads/Henry_Northcote_Resume_2022.pdf'


const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">

    </div>
    <div className="content">
      <div className="inner">
        <h1>Henry Northcote</h1>
        <p>
          Hi! I am Henry, a senior technical consultant and full stack developer, here is a quick link to my {' '}
          <a href="https://www.linkedin.com/in/henry-northcote/" target="_blank" rel="noreferrer" > LinkedIn profile</a>
          <br />
          And also a copy of my {' '}
          <a href={resume} popup>resume</a>
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            About Me
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Consultant
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Developer
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact Me
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
