import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin,} from "@fortawesome/free-brands-svg-icons"

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
                   <section> Copyright &copy; {new Date().getFullYear()} </section>
            <section>
                <a href="https://github.com/hnorthcote/" target="_blank" rel="noreferrer"> 
                    <FontAwesomeIcon icon={faGithub} />
                </a>&nbsp;
               
                &nbsp;<a href="https://www.linkedin.com/in/henry-northcote/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>

            </section>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
